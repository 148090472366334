<template>
  <div class="searchPageDiv">
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="headerSvgDiv" @click="onClickBack">
          <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="26" height="26">
            <path
                d="M395.21518 513.604544l323.135538-312.373427c19.052938-18.416442 19.052938-48.273447 0-66.660212-19.053961-18.416442-49.910737-18.416442-68.964698 0L291.75176 480.290811c-19.052938 18.416442-19.052938 48.273447 0 66.660212l357.633237 345.688183c9.525957 9.207709 22.01234 13.796214 34.497699 13.796214 12.485359 0 24.971741-4.588505 34.466999-13.82896 19.052938-18.416442 19.052938-48.242747 0-66.660212L395.21518 513.604544z"
                fill="#000000"></path>
          </svg>
        </div>
        <div class="searchKeywordDiv">
          <el-input class="searchKeywordInput"
                    placeholder="Search keyword"
                    v-model="keyword"
                    clearable
                    @clear="onSearchByKeyword">
          </el-input>
        </div>
        <div class="searchBtnDiv">
          <div class="headerSvgDiv">
            <el-button type="text" style="padding: 0; border: 0;" @click="onSearchByKeyword">
              <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                   width="26" height="26">
                <path
                    d="M426.666667 170.666667a256 256 0 1 0 0 512 256 256 0 0 0 0-512z m-341.333334 256a341.333333 341.333333 0 1 1 610.986667 209.322666l229.845333 229.845334a42.666667 42.666667 0 0 1-60.330666 60.330666l-229.845334-229.845333A341.333333 341.333333 0 0 1 85.333333 426.666667z"
                    fill="#fff"></path>
              </svg>
            </el-button>
          </div>
        </div>
      </div>
      <div class="informationDataDiv">
        <div v-if="searchResultData.length > 0">
          <div class="fifthItem">
            <div v-for="(information, index) in searchResultData" :key="'informationItem_' + index" class="informationItem"
                 @click="onClickItem(information.index)">
              <div class="informationContent">
                <div class="title">
                  {{ information.title }}
                </div>
                <div class="description">
                  {{ information.desc }}
                </div>
              </div>
              <img :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="lastSearchKeyword.length > 0" class="topSearches">
            <p class="searchTitle">Recent Searches</p>
            <div class="searchSubTitle" @click="onSearchByHistory(lastSearchKeyword)">
              <svg style="display: block; margin-right: 5px;" class="icon" viewBox="0 0 1024 1024"
                   xmlns="http://www.w3.org/2000/svg"
                   width="14" height="14">
                <path
                    d="M426.666667 170.666667a256 256 0 1 0 0 512 256 256 0 0 0 0-512z m-341.333334 256a341.333333 341.333333 0 1 1 610.986667 209.322666l229.845333 229.845334a42.666667 42.666667 0 0 1-60.330666 60.330666l-229.845334-229.845333A341.333333 341.333333 0 0 1 85.333333 426.666667z"
                    fill="#666666"></path>
              </svg>
              <span>
                {{ lastSearchKeyword }}
              </span>
            </div>
          </div>
          <div class="topSearches" style="margin-top: 20px;">
            <p class="searchTitle">Top Searches</p>
            <div v-for="(value, index) in topSearches" :key="'topSearchesItem_' + index" class="searchSubTitle"
                 @click="onSearchByHistory(value)">
              <svg style="display: block; margin-right: 5px;" class="icon" viewBox="0 0 1024 1024"
                   xmlns="http://www.w3.org/2000/svg"
                   width="14" height="14">
                <path
                    d="M426.666667 170.666667a256 256 0 1 0 0 512 256 256 0 0 0 0-512z m-341.333334 256a341.333333 341.333333 0 1 1 610.986667 209.322666l229.845333 229.845334a42.666667 42.666667 0 0 1-60.330666 60.330666l-229.845334-229.845333A341.333333 341.333333 0 0 1 85.333333 426.666667z"
                    fill="#666666"></path>
              </svg>
              <span>
                {{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerUI ref="footer"/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import InformationData from "@/view/template1/model/InformationData";

export default {
  components: {
    footerUI
  },
  data() {
    return {
      keyword: "",
      lastSearchKeyword: "",
      topSearches: ["Technological", "Interior"],
      searchResultData: [],
      ossUrl: this.$store.state.ossUrl,
    };
  },
  computed: {},
  created() {
    let lastSearchKeyword = localStorage.getItem("recent_search");
    if (lastSearchKeyword != null && lastSearchKeyword.trim().length > 0) {
      this.lastSearchKeyword = lastSearchKeyword.trim();
    }
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onClickBack() {
      this.$router.back();
    },
    onClickItem(id) {
      window.location.href = "/details/" + id;
    },
    onSearchByKeyword() {
      if (this.keyword != null) {
        this.searchResultData = InformationData.queryData(this.keyword.trim());
        this.lastSearchKeyword = localStorage.getItem("recent_search");
      }
    },
    onSearchByHistory(keyword) {
      this.keyword = keyword;
      this.onSearchByKeyword();
    },
    getUrlPath(imageUrl) {
      return imageUrl.startsWith("/") ? this.ossUrl + imageUrl : this.ossUrl + "/" + imageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.searchPageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      padding: 10px 15px 10px 10px;
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      z-index: 9999;
      background-color: white;

      .searchKeywordDiv {
        width: calc(100% - 131px);
        padding-left: 15px;

        .searchKeywordInput {
          width: 100%;

          .searchBtn {
            border: 0;
            margin-top: 1px;
            font-size: 20px;
            padding: 8px;
          }

          ::v-deep input {
            border: none;
            background-color: #F0F3F5;
            height: 40px;
            line-height: 40px;
            border-radius: 0;
          }
        }
      }

      .searchBtnDiv {
        width: 90px;
        background-color: #ef6c00;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .headerSvgDiv {
        width: 26px;
        height: 26px;
      }
    }

    .topSearches {
      padding: 0 20px;
      text-align: left;

      .searchTitle {
        margin-block: 0;
        margin-top: 10px;
      }

      .searchSubTitle {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        color: #666666;
      }
    }

    .informationDataDiv {
      text-align: left;
    }
    .fifthItem {
      padding: 5px;

      :first-child {
        margin-top: 0 !important;
      }

      .informationItem {
        margin: 15px 10px 0 10px;
        display: flex;
      }

      .coverImg {
        width: 100px;
        height: 75px;
      }

      .informationContent {
        width: calc(100% - 110px);
        padding-right: 10px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 16px;
          color: #3D3D3D;
          font-weight: bold;
          line-height: 20px;
        }

        .description {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px;
          color: #666666;
          margin-top: 5px;
        }
      }
    }

    .coverImg {
      width: 100%;
      display: block;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}
</style>
