<template>
  <div class="homePageDiv">
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="logoDiv">
          <img class="logoImage" src="/images/logo1.png" alt=""/>
        </div>
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="dialogVisible = true">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z"
                  fill="#ffffff"></path>
            </svg>
          </el-button>
        </div>
      </div>
      <div class="informationDataDiv">
        <div class="fifthItem">
          <div v-for="(information, index) in informationDataList" :key="'informationItem_' + index" class="informationItem"
               @click="onClickItem(information.index)">
            <div class="informationContent">
              <div class="title">
                {{ information.title }}
              </div>
              <div class="description">
                {{ information.desc }}
              </div>
            </div>
            <img :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
          </div>
        </div>
      </div>
    </div>
    <CategoryDialog :dialogVisible.sync="dialogVisible" @closeDialog="dialogVisible = false" :category-id="category.id"/>
    <footerUI/>
    <BackTopComp background-color="#1d366a"/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import CategoryDialog from './CategoryDialog';
import BackTopComp from "@/view/BackTopComp";
import InformationData from "@/view/template2/model/InformationData";

export default {
  components: {
    footerUI, BackTopComp, CategoryDialog
  },
  data() {
    return {
      dialogVisible: false,
      category: null,
      categoryList: JSON.parse(JSON.stringify(InformationData.categoryList)),
      informationDataList: [],
      ossUrl: this.$store.state.ossUrl,
    };
  },
  computed: {},
  created() {
    if (this.$route.path.includes("/category/")) {
      let categoryId = +this.$route.params.categoryId;
      this.category = this.categoryList.find(item => item.id === categoryId)
    }
    if (this.category == null) {
      this.category = this.categoryList[0];
    }
    this.informationDataList = InformationData.queryDataByType(this.category.name);
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickItem(id) {
      window.location.href = "/details/" + id;
    },
    getUrlPath(imageUrl) {
      return imageUrl.startsWith("/") ? this.ossUrl + imageUrl : this.ossUrl + "/" + imageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      background-color: #1d366a;
      height: 48px;
      padding: 0 15px 0 5px;
      justify-content: space-between;
      z-index: 999;

      .logoDiv .logoImage {
        display: block;
        height: 40px;
      }

      .headerSvgDiv {
        width: 26px;
        height: 26px;
      }
    }

    .informationDataDiv {
      text-align: left;

      .fifthItem {
        padding: 5px;

        :first-child {
          margin-top: 0 !important;
        }

        .informationItem {
          padding: 10px;
          display: flex;
          border-bottom: 1px solid #EBEEF5;
        }

        .coverImg {
          width: 125px;
          height: 95px;
        }

        .informationContent {
          width: calc(100% - 135px);
          padding-right: 10px;
          display: flex;
          align-content: space-around;
          flex-wrap: wrap;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 20px;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .coverImg {
        width: 100%;
        display: block;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
</style>
