import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import userAgreement from "@/view/userAgreement";
import privacyPolicy from "@/view/privacyPolicy";
import HomePage1 from "@/view/template1/HomePage";
import DetailsPage1 from "@/view/template1/DetailsPage";
import SearchPage1 from "@/view/template1/SearchPage";

import HomePage2 from "@/view/template2/HomePage";
import DetailsPage2 from "@/view/template2/DetailsPage";

Vue.use(Router);
let routes = [
    {
        path: "/",
        name: "homePage",
        component: getPageComp("homePage")
    },
    {
        path: "/category/:categoryId",
        name: "homePage",
        component: getPageComp("homePage")
    },
    {
        path: "/search",
        name: "searchPage",
        component: getPageComp("searchPage")
    },
    {
        path: "/details/:informationId",
        name: "detailsPage",
        component: getPageComp("detailsPage")
    },
    {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: privacyPolicy
    },
    {
        path: "/userAgreement",
        name: "userAgreement",
        component: userAgreement
    },
];
const router = new Router({
    //  mode: 'history',
    mode: process.env.IS_ELECTRON ? "hash" : "history",
    base: process.env.BASE_URL,
    routes
});

// 使用全局前置守卫来重置滚动位置
router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
        window.scrollTo(0, 0); // 滚动到页面的顶部
    }
    console.log("from.matched：", from);
    console.log("to.matched：", to);
    next(); // 必须调用next()来resolve这个钩子
});

function getPageComp(page) {
    let template = store.state.settingData.template;
    if (template === "TEMPLATE_1") {
        if (page === "homePage") {
            return HomePage1;
        } else if (page === "searchPage") {
            return SearchPage1;
        } else if (page === "detailsPage") {
            return DetailsPage1;
        }
    } else if (template === "TEMPLATE_2") {
        if (page === "homePage") {
            return HomePage2;
        } else if (page === "detailsPage") {
            return DetailsPage2;
        }
    }
    return null;
}
export default router;
