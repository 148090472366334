import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let store = new Vuex.Store({
    state: {
        settingData: window.settingData,
        ossUrl: "https://www.gamesoss.com/information",
    },
    getters: {},
    mutations: {

    },
    actions: {}
});
export default store;
