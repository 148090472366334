export default {
    informationList: [
        {
            index: 1,
            type: "Finance",
            title: "Maximizing Profits, Minimizing Risks: A Comprehensive Guide to Asset Management Ratios",
            desc: "In the dynamic world of finance, effective asset management is the cornerstone of success. Whether you're an individual investor or a corporate entity, understanding how to optimize your assets is vital.",
            img: "uploadsImg_v7/1698129035445.webp",
        },
        {
            index: 2,
            type: "Finance",
            title: "Demystifying Hedge Funds: Unraveling Theory, Operation, and Prospects",
            desc: "In the labyrinthine world of finance, hedge funds stand as enigmatic players, wielding influence and intrigue in equal measure.",
            img: "uploadsImg_v7/1698129035446.webp",
        },
        {
            index: 3,
            type: "Finance",
            title: "Embracing the Future: How Financial Planning Shapes Company Work Plans",
            desc: "In the ever-evolving landscape of business, success is a dynamic journey.",
            img: "uploadsImg_v7/1698129035447.webp",
        },
        {
            index: 4,
            type: "Finance",
            title: "Real Estate Revolution: Navigating the New Frontier of Investment",
            desc: "The practical application of real estate investment environment evaluation is a crucial step in the decision-making process for real estate investors.",
            img: "uploadsImg_v7/1698129035448.webp",
        },
        {
            index: 5,
            type: "Finance",
            title: "Retirement Rethought: From Golden Years to Silver Startups",
            desc: "The modern concept of retirement has undergone a remarkable transformation. It's no longer solely associated with leisurely days spent in relaxation.",
            img: "uploadsImg_v7/1698129035449.webp",
        },
        {
            index: 6,
            type: "Finance",
            title: "Navigating Real Estate Investment: Risk Analysis and Early Warning Systems",
            desc: "In the world of finance, where opportunities and pitfalls abound, real estate investment stands as a time-tested avenue for wealth creation.",
            img: "uploadsImg_v7/1698129035450.webp",
        },
        {
            index: 7,
            type: "Finance",
            title: "The Art of Financial Independence: Crafting a Life of Abundance",
            desc: "In a world filled with obligations, aspirations, and, let's face it, bills to pay, the notion of financial independence might seem like a mirage on the distant horizon.",
            img: "uploadsImg_v7/1698129035451.webp",
        },
        {
            index: 8,
            type: "Finance",
            title: "The Culinary Art of Risk Management: Navigating the Flavors of Private Equity",
            desc: "Risk control measures in private equity are like the secret ingredients of a gourmet dish.",
            img: "uploadsImg_v7/1698129035452.webp",
        },
        {
            index: 9,
            type: "Finance",
            title: "Navigating the Seas of Financial Wisdom: Unlocking Rational Wealth Growth",
            desc: "In the vast ocean of finance, where opportunities ebb and flow like tides, lies a treasure trove of possibilities waiting to be explored.",
            img: "uploadsImg_v7/1698129035453.webp",
        },
        {
            index: 10,
            type: "Finance",
            title: "Decoding Venture Capital: More Than Wealthy Investors and Banks",
            desc: "enture capital, often seen as the playground of wealthy investors and prominent financial institutions, is more than meets the eye.",
            img: "uploadsImg_v7/1698129035460.webp",
        },
        {
            index: 11,
            type: "Finance",
            title: "Green Money: How High Net Worth Investors Are Shaping ESG Investments",
            desc: "In a world that's increasingly focused on sustainability and responsible business practices, high net worth investors are making their voices heard.",
            img: "uploadsImg_v7/1698129035461.webp",
        },
        {
            index: 12,
            type: "Finance",
            title: "Navigating the Hedge Fund Jungle: Insights and Attitudes",
            desc: "In the complex world of finance, the hedge fund market stands as a formidable yet alluring landscape.",
            img: "uploadsImg_v7/1698129035462.webp",
        },
        {
            index: 13,
            type: "Finance",
            title: "Navigating the Storm: Applying Chaos Control to Real Estate Investment",
            desc: "Sailing Through the Chaos of Real Estate Investment",
            img: "uploadsImg_v7/1698129035463.webp",
        },
        {
            index: 14,
            type: "Finance",
            title: "The Paradox of Wealth: Why the Wealthy Don't Hand Over All Their Money to Private Equity Managers",
            desc: "In the dazzling realm of finance, where fortunes rise and fall, and investments hold the promise of boundless growth, a curious paradox unfolds.",
            img: "uploadsImg_v7/1698129035464.webp",
        },
        {
            index: 15,
            type: "It",
            title: "React vs. Angular: A Battle of Titans in the Front-End Framework Arena",
            desc: "Choosing a front-end framework for your web development project is akin to selecting a car for a cross-country road trip.",
            img: "uploadsImg_v7/1698803867010.webp",
        },
        {
            index: 16,
            type: "It",
            title: "Blockchain Unleashed: Revolutionizing Industries and Transforming Lives",
            desc: "Blockchain technology, initially synonymous with cryptocurrencies, has transcended the confines of digital coins to touch nearly every aspect of our lives.",
            img: "uploadsImg_v7/1698803867011.webp",
        },
        {
            index: 17,
            type: "It",
            title: "Cloud Computing in Business: Unleashing Collaborative Superpowers and Boosting Efficiency",
            desc: "In the realm of modern business, cloud computing isn't just a buzzword; it's a transformative superpower.",
            img: "uploadsImg_v7/1698803867012.webp",
        },
        {
            index: 18,
            type: "It",
            title: "JavaScript Frameworks Unleashed: A Savvy Guide to Choosing the Right One",
            desc: "Choosing a JavaScript framework can feel like strolling through a vast, bustling marketplace where every stall boasts a dazzling array of goods.",
            img: "uploadsImg_v7/1698803867013.webp",
        },
        {
            index: 19,
            type: "It",
            title: "Sailing Smooth Seas with Docker: A Developer's Voyage",
            desc: "Ahoy, fellow developers! Picture yourself as a captain embarking on a high-seas adventure, navigating through the intricate waters of modern software development.",
            img: "uploadsImg_v7/1698803867014.webp",
        },
        // 新加入文章
        {
            index: 20,
            type: "Accounting and Financial Services",
            title: "Unveiling the Future: The Unstoppable Rise of Blockchain in Financial Markets",
            desc: "In the dynamic landscape of global finance, a technological leviathan is reshaping the status quo.",
            img: "uploadsImg_v7/Unveiling the Future_ The Unstoppable Rise of Blockchain in Financial Markets.webp",
        },
        {
            index: 21,
            type: "Accounting and Financial Services",
            title: "Unlock Your Financial Future: Mastering Money Management",
            desc: "In today's ever-changing financial landscape, the ability to manage your money effectively is essential for securing a prosperous future.",
            img: "uploadsImg_v7/Unlock Your Financial Future_ Mastering Money Management.webp",
        },
        {
            index: 22,
            type: "Accounting and Financial Services",
            title: "Global Taxation Revolution: What's New in the World of International Tax Rules",
            desc: "The landscape of global taxation is undergoing a significant transformation.",
            img: "uploadsImg_v7/Global Taxation Revolution_ What's New in the World of International Tax Rules.webp",
        },
        {
            index: 23,
            type: "Accounting and Financial Services",
            title: "Green Returns: How to Align Your Portfolio with Your Values",
            desc: "In the ever-evolving landscape of the financial markets, a new paradigm is taking hold.",
            img: "uploadsImg_v7/Green Returns_ How to Align Your Portfolio with Your Values.webp",
        },
        {
            index: 24,
            type: "Accounting and Financial Services",
            title: "Revolutionizing Numbers: How Automation is Changing the Accounting Game",
            desc: "In an age where digital transformation is more than a buzzword, the realm of accounting stands on the brink of a paradigm shift.",
            img: "uploadsImg_v7/Revolutionizing Numbers_ How Automation is Changing the Accounting Game.webp",
        },
        //  Human Resources and Recruitment
        {
            index: 25,
            type: "Human Resources and Recruitment",
            title: "Elevate Your Team: Innovative Strategies for Peak Employee Engagement",
            desc: "In the realm of modern business, employee engagement stands as a cornerstone of organizational success.",
            img: "uploadsImg_v7/Elevate Your Team_ Innovative Strategies for Peak Employee Engagement.webp",
        },
        {
            index: 26,
            type: "Human Resources and Recruitment",
            title: "The Mosaic of Innovation: How Diversity and Inclusion Programs Are Shaping the Future",
            desc: "In the ever-evolving tapestry of modern business, each thread tells a story of innovation, progression, and the unyielding pursuit of excellence.",
            img: "uploadsImg_v7/The Mosaic of Innovation_ How Diversity and Inclusion Programs Are Shaping the Future.webp",
        },
        {
            index: 27,
            type: "Human Resources and Recruitment",
            title: "Unleashing Productivity: Innovative Remote Workforce Management Solutions",
            desc: "In an era where the traditional office walls have dissolved into digital workspaces, the concept of remote workforce management has swiftly ascended from a mere trend to an operational necessity.",
            img: "uploadsImg_v7/Unleashing Productivity_ Innovative Remote Workforce Management Solutions.webp",
        },
        {
            index: 28,
            type: "Human Resources and Recruitment",
            title: "Attracting Top Talent: Innovative Strategies Redefining Talent Acquisition",
            desc: "The competition for top-tier talent is fierce, and companies are increasingly recognizing that traditional recruitment methods are no longer sufficient.",
            img: "uploadsImg_v7/Attracting Top Talent_ Innovative Strategies Redefining Talent Acquisition.webp",
        },
        {
            index: 29,
            type: "Human Resources and Recruitment",
            title: "Thriving Minds at Work: Pioneering Mental Health Initiatives for Today's Workforce",
            desc: "In today's fast-paced business environment, the mental health of employees is an issue that can no longer be ignored.",
            img: "uploadsImg_v7/Thriving Minds at Work_ Pioneering Mental Health Initiatives for Today's Workforce.webp",
        },
        // Consulting Services
        {
            index: 30,
            type: "Consulting Services",
            title: "Unleash Your Company's Potential: Innovative Strategies from Top Business Transformation Experts",
            desc: "In a world where the pace of change is relentless, businesses face an imperative to adapt or face obsolescence.",
            img: "uploadsImg_v7/Unleash Your Company's Potential_ Innovative Strategies from Top Business Transformation Experts.webp",
        },
        {
            index: 31,
            type: "Consulting Services",
            title: "Green Horizons: Leading the Charge in Sustainability Consulting",
            desc: "John Muir, a revered naturalist, once beautifully expressed a fundamental truth about our existence: 'The sun shines not on us but in us.",
            img: "uploadsImg_v7/Green Horizons_ Leading the Charge in Sustainability Consulting.webp",
        },
        {
            index: 32,
            type: "Consulting Services",
            title: "Navigating the Storm: Advanced Tactics for Effective Risk Management",
            desc: "In the unpredictable and often perilous world of business and finance, the ability to adeptly manage risk is a vital skill akin to a captain steering their ship through an unrelenting storm.",
            img: "uploadsImg_v7/Navigating the Storm_ Advanced Tactics for Effective Risk Management.webp",
        },
        {
            index: 33,
            type: "Consulting Services",
            title: "Revolutionize Your Brand: Mastering the Art of Customer Experience Consulting",
            desc: "In an era where products and services are becoming increasingly commoditized, brands that stand out are those that deliver exceptional customer experiences.",
            img: "uploadsImg_v7/Revolutionize Your Brand_ Mastering the Art of Customer Experience Consulting.webp",
        },
        {
            index: 34,
            type: "Consulting Services",
            title: "Transformative Tech Tactics: Elevate Your Enterprise with IT Strategy Consulting",
            desc: "In the digital age, where technology evolves at the speed of light, businesses that fail to adapt are doomed to be left in the dust of their competitors.",
            img: "uploadsImg_v7/Transformative Tech Tactics_ Elevate Your Enterprise with IT Strategy Consulting.webp",
        },
//  Home Decoration
        {
            index: 35,
            type: "Home Decoration",
            title: "Leather Lifesavers: Pro Tips to Protect and Perfect Your Furniture",
            desc: "As you settle into the supple embrace of your leather chair, the one that has become your steadfast companion through late-night reads and lazy Sunday afternoons, have you ever paused to consider the tales it could tell? If your cherished leather pieces could speak, they might whisper secrets of ancient craftsmanship, recount the tender care they've received, or perhaps even plead for a little TLC.",
            img: "uploadsImg_v7/Leather Lifesavers_ Pro Tips to Protect and Perfect Your Furniture.webp",
        },
        {
            index: 36,
            type: "Home Decoration",
            title: "Eco-Chic Interiors: Finding the Perfect Balance of Style and Sustainability",
            desc: "In the world of interior design, a new trend has steadily emerged, marrying the aesthetics of modern chic with the ethics of environmental awareness.",
            img: "uploadsImg_v7/Eco-Chic Interiors_ Finding the Perfect Balance of Style and Sustainability.webp",
        },
        {
            index: 37,
            type: "Home Decoration",
            title: "Elevate Your Space: Inspirational Client Makeovers That Redefine Style",
            desc: "In the realm of interior design, a transformation signifies more than just a change of scenery—it's a metamorphosis of lifestyle and energy.",
            img: "uploadsImg_v7/Elevate Your Space_ Inspirational Client Makeovers That Redefine Style.webp",
        },
        {
            index: 38,
            type: "Home Decoration",
            title: "Revolutionize Your Space: The Top 5 Home Furnishing Trends of 2024 Revealed",
            desc: "The home is no longer just a place for shelter; it's a living canvas, an expression of one's personality, and a testament to the times we live in.",
            img: "uploadsImg_v7/_Revolutionize Your Space_ The Top 5 Home Furnishing Trends of 2024 Revealed_.webp",
        },
        {
            index: 39,
            type: "Home Decoration",
            title: "Transform Your Backyard into a Zen Retreat with Stylish Patio Furniture",
            desc: "In the hustle and bustle of modern life, finding a serene oasis where you can unwind and rejuvenate is more important than ever.",
            img: "uploadsImg_v7/Transform Your Backyard into a Zen Retreat with Stylish Patio Furniture.webp",
        },
        {
            index: 40,
            type: "Home Decoration",
            title: "Discover Sophistication: Preview Our Unique Modern Furnishings Before Anyone Else!",
            desc: "Imagine stepping into a space where each piece of furniture echoes a story of design innovation and meticulous craftsmanship—a space where sophistication is not just an aesthetic choice, but a living experience.",
            img: "uploadsImg_v7/Discover Sophistication_ Preview Our Unique Modern Furnishings Before Anyone Else!.webp",
        },
//  Healthcare and Medical Services
        {
            index: 41,
            type: "Healthcare and Medical Services",
            title: "Virtual Healing: How Telemedicine is Redefining Medical Care",
            desc: "The concept of medical care has undergone a profound transformation with the advent of telemedicine.",
            img: "uploadsImg_v7/_Virtual Healing_ How Telemedicine is Redefining Medical Care_.webp",
        },
        {
            index: 42,
            type: "Healthcare and Medical Services",
            title: "Tailored for You: How Personalized Medicine is Revolutionizing Health Care",
            desc: "The medical field has long sought to provide the best care possible, but the advent of Personalized Medicine has begun to transform this domain in profound ways.",
            img: "uploadsImg_v7/Tailored for You_ How Personalized Medicine is Revolutionizing Health Care.webp",
        },
        {
            index: 43,
            type: "Healthcare and Medical Services",
            title: "Guarding Patient Secrets: Innovative Strategies for Secure Health Information",
            desc: "In the modern digital landscape, where sensitive data is constantly at risk of exposure and misuse, healthcare data security stands as a crucial bastion against the tide of cyber threats.",
            img: "uploadsImg_v7/_Guarding Patient Secrets_ Innovative Strategies for Secure Health Information_.webp",
        },
        {
            index: 44,
            type: "Healthcare and Medical Services",
            title: "Beyond the Taboo: Embracing the Conversation on Mental Health",
            desc: "The sun crests the horizon, casting a golden glow over a world that seems to wake up with a buzz of energy.",
            img: "uploadsImg_v7/Beyond the Taboo_ Embracing the Conversation on Mental Health.webp",
        },
        {
            index: 45,
            type: "Healthcare and Medical Services",
            title: "Cutting-Edge Healing: How Innovative Medical Devices Are Transforming Healthcare",
            desc: "In an era marked by rapid technological advancement, the landscape of healthcare has been revolutionized by a new vanguard of medical devices.",
            img: "uploadsImg_v7/Cutting-Edge Healing_ How Innovative Medical Devices Are Transforming Healthcare.webp",
        },
    ],
    categoryList: [
        {id: 1, name: 'Finance'},
        {id: 2, name: 'It'},
        {id: 3, name: 'Accounting and Financial Services'},
        {id: 4, name: 'Human Resources and Recruitment'},
        {id: 5, name: 'Consulting Services'},
        {id: 6, name: 'Home Decoration'},
        {id: 7, name: 'Healthcare and Medical Services'}
    ],
    queryDataByType(type) {
        const list = []
        this.informationList.forEach(item => {
            if (type === item.type) {
                list.push(item)
            }
        })
        return list
    },
    queryRecommend(idx, count) {
        let result = [];
        let data = JSON.parse(JSON.stringify(this.informationList));
        while (result.length < count) {
            const index = Math.floor(Math.random() * data.length);
            let temp = data[index];
            if (temp.index !== idx) {
                result.push(data[index]);
            }
            data.splice(index, 1);
        }

        return result;
    },
}
