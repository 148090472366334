<template>
  <div class="homePageDiv">
    <div class="backUpDiv" @click="onClickHomepage">
      <i class="el-icon-back"/>
    </div>
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="logoDiv">
          <img class="logoImage" src="/images/logo1.png" alt=""/>
        </div>
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="dialogVisible = !dialogVisible">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z"
                  fill="#ffffff"></path>
            </svg>
          </el-button>
        </div>
      </div>
      <div class="informationDataDiv">
        <div class="informationTitle">
          {{ informationData.title }}
        </div>
        <div class="informationContent">
          <div v-html="informationData.content" id="htmlContent"></div>
        </div>
      </div>
      <div class="recommendDataDiv">
        <div class="title">
          —————— Hot Topic ——————
        </div>
        <div class="content">
          <div v-for="(information, index) in recommendDataList" :key="'informationItem_' + index" class="informationItem"
               @click="onClickItem(information.index)">
            <img :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
            <div class="titleContent">
              <div class="title">
                {{ information.title }}
              </div>
              <div class="description">
                {{ information.desc }}
              </div>
            </div>
          </div>
          <el-divider style="background-color: black;"></el-divider>
        </div>
      </div>
    </div>
    <CategoryDialog :dialogVisible.sync="dialogVisible" @closeDialog="dialogVisible = false"/>
    <footerUI/>
    <BackTopComp background-color="#1d366a"/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import CategoryDialog from './CategoryDialog';
import BackTopComp from "@/view/BackTopComp";
import InformationData from "@/view/template2/model/InformationData";
import InformationDetails from "@/view/template2/model/InformationDetails";

export default {
  components: {
    footerUI, BackTopComp, CategoryDialog
  },
  data() {
    return {
      ossUrl: this.$store.state.ossUrl,
      dialogVisible: false,
      informationData: {},
      recommendDataList: [],
    };
  },
  computed: {

  },
  created() {
    let informationId = +this.$route.params.informationId;
    let informationData = InformationDetails.queryByIndex(informationId);
    console.log(informationData)
    informationData.content = informationData.content.replaceAll('__IMG__', this.ossUrl + "/uploadsImg_v7");
    this.informationData = informationData;
    this.recommendDataList = InformationData.queryRecommend(informationId, 5);
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickHomepage() {
      this.$router.back();
    },
    onSearchByTag(category) {
      window.location.href = "/category/" + category.id;
    },
    onClickItem(id) {
      window.location.href = "/details/" + id;
    },
    getUrlPath(imageUrl) {
      return imageUrl.startsWith("/") ? this.ossUrl + imageUrl : this.ossUrl + "/" + imageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .backUpDiv {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 58px;
    left: 10px;
    border-radius: 5px;
    color: white;

    i {
      font-size: 20px;
      font-weight: bold;
      display: block;
    }
  }

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      background-color: #1d366a;
      height: 48px;
      padding: 0 15px 0 5px;
      justify-content: space-between;
      z-index: 999;

      .logoDiv .logoImage {
        display: block;
        height: 40px;
      }

      .headerSvgDiv {
        width: 26px;
        height: 26px;
      }
    }

    .informationDataDiv {
      padding: 20px;
      text-align: left;

      .informationTitle {
        font-size: 20px;
        font-weight: bold;
        color: #353535;
        line-height: 22px;
      }

      .informationContent {
        font-size: 14px;
        font-weight: 400;
        color: #3B3B3B;
        line-height: 18px;

        ::v-deep h2 {
          line-height: 25px;
        }

        ::v-deep ul {
          padding: 0;
        }
        /* ul */
        ::v-deep li {
          list-style: none;
        }

        /* img */
        ::v-deep img {
          width: 100%;
          max-height: 180px;
          object-fit: cover;
        }
      }
    }

    .recommendDataDiv {
      padding: 0 20px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        text-align: center;
      }

      .content {
        margin-top: 10px;

        .informationItem {
          margin-bottom: 20px;
          display: flex;

          .coverImg {
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 10px;
            object-fit: cover;
          }

          .titleContent {
            width: calc(100% - 115px);
            padding-left: 15px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 22px;
            text-align: left;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            color: #666666;
            text-align: left;
          }
        }
      }
    }
  }
  .el-divider {
    background-color: black;
  }
}
</style>
