<template>
  <el-dialog :visible.sync="dialogShow" width="85%" top="58px" :show-close="false">
    <div v-for="(_category, index) in categoryList" :key="'news_category_' + index" class="categoryItemDiv"
         @click="onSearchByTag(_category)" :style="getDrawerItemStyle(_category)">
      {{ _category.name }}
    </div>
  </el-dialog>
</template>

<script>

import InformationData from "@/view/template2/model/InformationData";

export default {
  name: "CategoryDialog",
  computed: {
    dialogShow: {
      get() {
        return this.dialogVisible;
      },
      set(newVal) {
        this.$emit('closeDialog', newVal);
      }
    },
    getDrawerItemStyle() {
      return _category => ({
        'color': _category.id === this.categoryId ? '#fff' : "#1d366a",
        'background-color': _category.id === this.categoryId ? '#1d366a' : "#fff",
      });
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      categoryList: JSON.parse(JSON.stringify(InformationData.categoryList))
    }
  },
  created() {
  },

  methods: {
    onSearchByTag(category) {
      window.location.href = "/category/" + category.id;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 15px;
  text-align: left;

  .categoryItemDiv {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 10px;
  }
}
</style>
